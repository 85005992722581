import { companyRequests } from '@/api/requests';
import { companySchema } from '@/api/schemes';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

export function useGetCompany() {
  async function requestFn() {
    return companyRequests.getCompany();
  }

  const query = useQuery<companySchema.CompanyDetail>(
    ['getCompany'],
    requestFn,
  );

  return query;
}

export function useUpdateCompany() {
  const queryClient = useQueryClient();
  async function mutationFn(params: companySchema.UpdateCompanyParams) {
    return companyRequests.updateCompany(params!);
  }

  const mutation = useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries('getCompany');
      toast.success('Configurações atualizadas com sucesso!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
    onError: () => {
      toast.error('Ocorreu um erro na atualização das configurações.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
  });

  return mutation;
}

export function useUpdateCompanyImages() {
  const queryClient = useQueryClient();
  async function mutationFn(params: FormData) {
    return companyRequests.updateCompanyImages(params!);
  }

  const mutation = useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries('getCompany');
      toast.success('Imagens atualizadas com sucesso!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
    onError: () => {
      toast.error('Ocorreu um erro na atualização das imagens.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
  });

  return mutation;
}

export function useSendPhoneCode() {
  async function mutationFn(params: companySchema.SendPhoneCodeParams) {
    return companyRequests.sendPhoneCode(params!);
  }

  const mutation = useMutation(mutationFn, {
    onSuccess: () => {
      toast.success('Código enviado com sucesso!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
    onError: () => {
      toast.error('Ocorreu um erro no envio do código.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
  });

  return mutation;
}

export function useSendEmailCode() {
  async function mutationFn(params: companySchema.SendEmailCodeParams) {
    return companyRequests.sendEmailCode(params!);
  }

  const mutation = useMutation(mutationFn, {
    onSuccess: () => {
      toast.success('Código enviado com sucesso!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
    onError: () => {
      toast.error('Ocorreu um erro no envio do código.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
  });

  return mutation;
}

export function useCheckPhoneCode() {
  async function mutationFn(params: companySchema.CheckPhoneCodeParams) {
    return companyRequests.checkPhoneCode(params!);
  }

  const mutation = useMutation(mutationFn, {
    onSuccess: () => {
      toast.success('Celular verificado com sucesso!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
    onError: (error: any) => {
      let errorMessage = 'Ocorreu um erro na verificação do celular.';

      if (error?.response?.data?.message) {
        errorMessage = error?.response?.data?.message;
      }

      toast.error(errorMessage, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
  });

  return mutation;
}

export function useCheckEmailCode() {
  async function mutationFn(params: companySchema.CheckEmailCodeParams) {
    return companyRequests.checkEmailCode(params!);
  }

  const mutation = useMutation(mutationFn, {
    onSuccess: () => {
      toast.success('E-mail verificado com sucesso!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
    onError: (error: any) => {
      let errorMessage = 'Ocorreu um erro na verificação do e-mail.';

      if (error?.response?.data?.message) {
        errorMessage = error?.response?.data?.message;
      }

      toast.error(errorMessage, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
  });

  return mutation;
}
